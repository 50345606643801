import React from 'react';
// import { decorate, observable, configure, action } from "mobx";
import { observer } from "mobx-react";
// import { Component } from 'react';
import { peopleList } from './data';
import { appStore } from './App';
// import Store from './App';
// import { TopBar, KeySelector } from './TopBar';

// configure({ enforceActions: "observed" });



export class CompName extends React.Component {
  render() {
    return(

      <h3>{this.props.insertName}
      </h3>
      )
  }
}
CompName = observer(CompName)


export class Catalogue extends React.Component {

  loopCatalogue() {
    const { selectSong } = this.props.store;

    return this.props.loopCatalogue.map(function (update, index) {
      
      return (
        <li className='songItem ' key={index} >
        <button className='link-button' onClick={selectSong} value={update.songTitle} name={update.fileName}> 
        {update.songTitle}
        {" (" + update.keyOriginal + ")"} </button>
        </li>
      );
    });
  }
  //end of "updates()" function

  render() {
    // const { store } = this.props;
    // const { selectedItem, selectSong } = this.props.store;
  
    // console.log("from Catalogue: " + selectedItem);
    return (
      <div className='catalogue '>
        <ul>
        {null}
        <p>
        {this.loopCatalogue()}
        </p>
        </ul>
        </div>
    );
    //end of return React function
  }
  //end of render() function
}
// end of Catalogue Component
Catalogue = observer(Catalogue)


export class Card extends React.Component {

  render() {
    // const { store } = this.props
    return(
      <div className='card'>
        <CompName store={appStore} insertName={this.props.defineName.name} />
        <Catalogue store={appStore} loopCatalogue={this.props.defineName.songs} />
        </div>
      );
  }
}
Card = observer(Card)

export class LeftSideBar extends React.Component {
  render() {
    // const { store } = this.props
    return(
      <div className="leftSideBar">
      <Card store={appStore} defineName={peopleList[0]} />
      <Card store={appStore} defineName={peopleList[1]} />
      <Card store={appStore} defineName={peopleList[2]} />
      <Card store={appStore} defineName={peopleList[3]} />
      <Card store={appStore} defineName={peopleList[4]} />
      <Card store={appStore} defineName={peopleList[5]} />
      <Card store={appStore} defineName={peopleList[6]} />
      <Card store={appStore} defineName={peopleList[7]} />
      <Card store={appStore} defineName={peopleList[8]} />
      <Card store={appStore} defineName={peopleList[9]} />
      <Card store={appStore} defineName={peopleList[10]} />
      <Card store={appStore} defineName={peopleList[11]} />
      <Card store={appStore} defineName={peopleList[12]} />
      <Card store={appStore} defineName={peopleList[13]} />
      <Card store={appStore} defineName={peopleList[14]} />
      <Card store={appStore} defineName={peopleList[15]} />
      <Card store={appStore} defineName={peopleList[16]} />
      <Card store={appStore} defineName={peopleList[17]} />
      <Card store={appStore} defineName={peopleList[18]} />
      <Card store={appStore} defineName={peopleList[19]} />
      <Card store={appStore} defineName={peopleList[20]} />

      </div>
      );
  }
}
LeftSideBar = observer(LeftSideBar)

