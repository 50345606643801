import React from 'react';
// import ReactDOM from 'react-dom';
import { render } from 'react-snapshot';
// import { autorun } from 'mobx';
// import { Provider } from 'mobx-react';
import './index.css';
import { App } from './components/App';
import { App2 } from './components/App';
import { App3 } from './components/App';
import { Router, Route, Link, browserHistory, IndexRoute } from 'react-router';

// import { render } from 'react-dom';

// function render() {


// ReactDOM.render(<App stories={stories} onArchive={(objectID) => {console.log(objectID);}} />, 

// <Provider storyStore={store.storyStore} archiveStore={store.archiveStore}>

render(
	
		<Router history = {browserHistory}>
		<Route path="/" component={App2} />
		<Route path="/score" component={App} />
		<Route path="/contact" component={App3} />

		</Router>, 

document.getElementById('root'));

// }
// autorun(render);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// registerServiceWorker();
