import React from 'react';
import ReactDOM from 'react-dom';
import { decorate, observable, configure, action } from "mobx";
import { observer } from "mobx-react";
// import { Component } from 'react';
// import { peopleList } from './data';
import { TopBar } from './TopBar';
import { TopBar2 } from './TopBar';
import { LeftSideBar } from './LeftSideBar';
import { Router, Route, Link, browserHistory, IndexRoute } from 'react-router';
import { appStore } from './App';
import { AdUnit } from './App';


export class HowToPage extends React.Component {

render() {
  const { keySelected } = this.props.store;
  return(
    <div className="howToView">
    <h1>How to use Classical Chord Book</h1>

    <br></br>
    
    <h2>What is Classical Chord Book? </h2>
    <p>Classical Chord Book is an ideal reference guide for musicians of all levels who feel more comfortable with chord names than conventional musical notation commonly used in the classical music. 
    Classical Chord Book currently contains scores for over 30 popular songs from Baroque, Romantic and Classical eras. 
    All the scores have chord notation and key selector tool to assist your learning.  
     </p>
    
    <br></br>
 
    <h2>Chord notation</h2>
    <p>If you are interested in playing classical music but not so excited about sight reading, this tool is for you. 
    Perhaps you started with popular or jazz music. Perhaps you never got around to learn conventional musical notation. 
    You can use the chord names to guide you with your learning.  </p>
    <br></br>

    <h2>Change the keys</h2>
    <p>Use the Key Selector tool on your top right to transpose the song into other keys. 
    Perhaps the original key makes a very difficult finger positions for your guitar or violin. Or maybe you just don’t like to see so many sharps and flats. 
    Toggle the key up and down to find the right key for you.  </p>
    <br></br>

    <h2>For brass and wind instruments</h2>
    <p>If you are playing an instrument that is not in Concert C, use the Key Selector to find the right key. 
    For example, you drop the key by -3 for Eb instruments such as alto saxophone or raise it by +2 for Bb instruments like trumpet or tenor saxophone.   </p>
    <br></br>

    <h2>Mixed ensembles</h2>
    If you are playing in an ensemble with mixed instruments, you can use the key selector to your advantage. 
    For example, pianist and bassist can use the default key whilst brass and wind instruments play from transposed versions of the same tune.   

    <br></br>
    <br></br>
    <br></br>
    <AdUnit />
    </div>

    )
}

}
HowToPage = observer(HowToPage)





export class Contact extends React.Component {

render() {
  // const { keySelected } = this.props.store;
  return(
    <div className="howToView">
    <br></br>
    <h2>Let us know your thoughts, comments and requests. </h2>
    <br></br>

   <iframe className="contactFormEmbed" src="https://docs.google.com/forms/d/e/1FAIpQLScvATjWTcj3K2vrBBkcni9MTsfntwqFJrymt1cjt_vi15TE1A/viewform?embedded=true" frameborder="0" marginheight="0" marginwidth="0">Loading...</iframe>
    </div>

    )
}

}
Contact = observer(Contact)





// export default App;