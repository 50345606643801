import React from 'react';
import ReactDOM from 'react-dom';
import { decorate, observable, configure, action } from "mobx";
import { observer } from "mobx-react";
// import { Component } from 'react';
// import { peopleList } from './data';
import { TopBar } from './TopBar';
import { TopBar2 } from './TopBar';
import { LeftSideBar } from './LeftSideBar';
import { Router, Route, Link, browserHistory, IndexRoute } from 'react-router';
import { HowToPage } from './HowToPage';
import { Contact } from './HowToPage';


configure({ enforceActions: "observed" });


export class Store extends React.Component {
keySelected = "Default key";
selectedItem = "Adagio in G min";
songFileName = "Albinioni_Adagio in G min";

selectYourKey = e => (this.keySelected = e.target.value);

selectSong = e => (
  this.selectedItem = e.target.value, 
  this.songFileName = e.target.name,
  this.keySelected = "Default key"
  , browserHistory.push('/score')
  );
// cancelKey = e => (this.keySelected = "0");

logoClicked = e => (
  // this.keySelected = "Default key",
  // this.selectedItem = "Adagio in G min",
  // this.songFileName = "Albinioni_Adagio in G min",
  // console.log("logo clicked"),
  browserHistory.push('/')
  );

howToClicked = e => (
  // console.log("How To clicked"),
  this.keySelected = "Default key",
  this.selectedItem = "Adagio in G min",
  this.songFileName = "Albinioni_Adagio in G min",
  browserHistory.push('/score')
  );

contactClicked = e => (
  // console.log("Contact form clicked"),
  browserHistory.push('/contact')
  );

}







decorate(Store, {
  // people: observable,
  keySelected: observable,
  selectedItem: observable,
  songFileName: observable,
  selectYourKey: action,
  selectSong: action,
  logoClicked: action,
  howToClicked: action,
  contactClicked: action

  // peopleList: observable
  // animalList: observable,
  // animal1: observable
})
export const appStore = new Store()




class ScoreView extends React.Component {

  render() {
    const { keySelected, selectedItem, songFileName } = this.props.store;
    // console.log("from ScoreView: " + keySelected);
    // console.log("from ScoreView: " + selectedItem);
    const filePath = "assets/" + songFileName + "/" + keySelected + ".pdf";
    // const filePath = "../Default key.pdf";
    console.log("file name = " + songFileName);
    console.log("song selected = " + selectedItem);
    console.log("file path = " + filePath);
    

    return(
      <div className='scoreContainer'>

      <object className='scorePDF' data={filePath} alt="music score"></object>


      <p className='currentKey'> 
      Selected key = {keySelected}
      </p>
  
      <p className="miniText">
      Use Key Selector to find the right key for your instrument.
      </p>


    
      </div>
      );
  }
}
ScoreView = observer(ScoreView)



export class AdUnit extends React.Component {
  componentDidMount () {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }
  render() {
    return(
      <div className='adUnit'>
        <ins className='adsbygoogle'
          style={{ display: 'block' }}
          data-ad-client='ca-pub-9258711311475053'
          data-ad-slot='5252946746'
          data-ad-format='auto' 
          data-full-width-responsive="true" />
      </div>

      )
  }


}
AdUnit = observer(AdUnit)





export class App extends React.Component {

  render() {
   
    return(
      <div>
      <TopBar store={appStore} />
      <LeftSideBar store={appStore} />
      <ScoreView store={appStore} />
      </div>
      );
  }
}

export class App2 extends React.Component {

  render() {
   
    return(
      <div>
      <TopBar2 store={appStore} />
      <LeftSideBar store={appStore} />
      <HowToPage store={appStore} />
      </div>
      );
  }
}


export class App3 extends React.Component {

  render() {
   
    return(
      <div>
      <TopBar2 store={appStore} />
      <LeftSideBar store={appStore} />
      <Contact store={appStore} />
      </div>
      );
  }
}




// export default App;