
export const peopleList  = [

{
  id: 0,
  name: 'Albinioni',
  songs: [{
    tuneId: 1,
    Composer: 'Albinioni',
    songTitle: 'Adagio in G min',
    keyOriginal: 'G min',
    fileName: 'Albinioni_Adagio in G min'
  }]
},

{
  id: 1,
  name: 'J.S. Bach',
  songs: [{
    tuneId: 1,
    Composer: 'Bach',
    songTitle: 'Aria on G String',
    keyOriginal: 'D',
    fileName: 'Bach_Aria On G String'
  }, {
    tuneId: 2,
    Composer: 'Bach',
    songTitle: 'Jesus, Joy of Mankind',
    keyOriginal: 'G',
    fileName: 'JJM'
  }, {
    tuneId: 3,
    Composer: 'Bach',
    songTitle: 'Cello Suite no1 in G (Prelude)',
    keyOriginal: 'G',
    fileName: 'Bach_Cello Suite G pre'
  }, {
    tuneId: 4,
    Composer: 'Bach',
    songTitle: 'Minuet (Bach)',
    keyOriginal: 'G',
    fileName: 'Bach_Minuet'
  }, {
    tuneId: 5,
    Composer: 'Bach',
    songTitle: 'Siciliano (Flute Sonata no.2)',
    keyOriginal: 'Gm',
    fileName: 'Bach_Siciliano-Flute Sonata no.2'
  }]
}, 

{
  id: 2,
  name: 'Beethoven',
  songs: [{
    tuneId: 1,
    Composer: 'Beethoven',
    songTitle: 'Fur Elise',
    keyOriginal: 'Am',
    fileName: 'Beethoven_Fur Elise'
  }, {
    tuneId: 2,
    Composer: 'Beethoven',
    songTitle: 'Ode to Joy',
    keyOriginal: 'D',
    fileName: 'Beethoven_Ode to Joy'
  }, {
    tuneId: 3,
    Composer: 'Beethoven',
    songTitle: 'Pathetique - Piano Sonata no8 2nd mvmt',
    keyOriginal: 'Cm',
    fileName: 'Beethoven_Piano Sonata no8 2nd'
  }]
},

{
  id: 3,
  name: 'Bizet',
  songs: [{
    tuneId: 1,
    Composer: 'Bizet',
    songTitle: 'Habanera - from Carmen',
    keyOriginal: 'D',
    fileName: 'Bizet_Carmen Habanera'
  }]
},

{
  id: 4,
  name: 'Boccherini',
  songs: [{
    tuneId: 1,
    Composer: 'Boccherini',
    songTitle: 'Minuet (Boccherini)',
    keyOriginal: 'A',
    fileName: 'Boccherini_Minuet'
  }]
},

{
  id: 5,
  name: 'Brahms',
  songs: [{
    tuneId: 1,
    Composer: 'Brahms',
    songTitle: 'Hungarian Dance no.5',
    keyOriginal: 'F#-',
    fileName: 'Brahms_Hungarian Dance no5'
  }, {
    tuneId: 2,
    Composer: 'Brahms',
    songTitle: 'Lullaby',
    keyOriginal: 'Eb',
    fileName: 'Brahms_Lullaby'
  }]
},

{
  id: 6,
  name: 'Chopin',
  songs: [{
    tuneId: 1,
    Composer: 'Chopin',
    songTitle: 'Etude Op 10 No. 3- Tristesse - ',
    keyOriginal: 'E',
    fileName: 'Chopin_Etude op10 no3'
  }, {
    tuneId: 2,
    Composer: 'Chopin',
    songTitle: 'Prelude',
    keyOriginal: 'Db',
    fileName: 'Chopin_Prelude in Db'
  }]
},

{
  id: 7,
  name: 'Debussy',
  songs: [{
    tuneId: 1,
    Composer: 'Debussy',
    songTitle: 'Clair de la Lune',
    keyOriginal: 'Db',
    fileName: 'Debussy_Clair de la Lune'
  }]
},

{
  id: 8,
  name: 'Dvorak',
  songs: [{
    tuneId: 1,
    Composer: 'Dvorak',
    songTitle: 'From the New World',
    keyOriginal: 'D',
    fileName: 'Dvorak_From the New World'
  }, {
    tuneId: 1,
    Composer: 'Dvorak',
    songTitle: 'Humoresque',
    keyOriginal: 'Gb',
    fileName: 'Dvorak_Humoresque'
  }]
},

{
  id: 9,
  name: 'Elgar',
  songs: [{
    tuneId: 1,
    Composer: 'Elgar',
    songTitle: 'Pomp and Circumstance',
    keyOriginal: 'G',
    fileName: 'Elgar_Pomp and Circumstance'
  }, {
    tuneId: 2,
    Composer: 'Elgar',
    songTitle: 'Slaut d\'Amour',
    keyOriginal: 'F',
    fileName: 'Elgar_Salut dAmour'
  }]
},

{
  id: 10,
  name: 'Handel',
  songs: [{
    tuneId: 1,
    Composer: 'Handel',
    songTitle: 'Air from Water Music',
    keyOriginal: 'F',
    fileName: 'Handel_Air'
  }, {
    tuneId: 2,
    Composer: 'Handel',
    songTitle: 'Ombra Mai Fu',
    keyOriginal: 'F',
    fileName: 'Handel_Ombra Mai Fu'
  }]
},

{
  id: 11,
  name: 'Massenet',
  songs: [{
    tuneId: 1,
    Composer: 'Massenet',
    songTitle: 'Meditation from Thais',
    keyOriginal: 'D',
    fileName: 'Massenet_Thais-Meditation'
  }]
}, 

{
  id: 12,
  name: 'Mozart',
  songs: [{
    tuneId: 1,
    Composer: 'Mozart',
    songTitle: 'Symphony no40',
    keyOriginal: 'Gm',
    fileName: 'Mozart_Symphony no40'
  }]
}, 

{
  id: 13,
  name: 'Offenbach',
  songs: [{
    tuneId: 1,
    Composer: 'Offenbach',
    songTitle: 'Barcarolle',
    keyOriginal: 'D',
    fileName: 'Offenbach_Barcarolle'
  }]
}, 

{
  id: 14,
  name: 'Pachelbel',
  songs: [{
    tuneId: 1,
    Composer: 'Pachelbel',
    songTitle: 'Pachelbel\'s Canon',
    keyOriginal: 'D',
    fileName: 'Pachelbel_Canon in D'
  }]
}, 

{
  id: 15,
  name: 'Rodrigo',
  songs: [{
    tuneId: 1,
    Composer: 'Rodrigo',
    songTitle: 'Concierto de Aranjuez',
    keyOriginal: 'D',
    fileName: 'Rodrigo_Concierto de Aranjuez'
  }]
}, 

{
  id: 16,
  name: 'Schubert',
  songs: [{
    tuneId: 1,
    Composer: 'Schubert',
    songTitle: 'An Die Musik',
    keyOriginal: 'D',
    fileName: 'Schubert_An Die Musik'
  }, {
    tuneId: 2,
    Composer: 'Schubert',
    songTitle: 'Ave Maria',
    keyOriginal: 'Bb',
    fileName: 'Schubert_Ave Maria'
  }]
}, 

{
  id: 17,
  name: 'Smetana',
  songs: [{
    tuneId: 1,
    Composer: 'Smetana',
    songTitle: 'Moldau',
    keyOriginal: 'E-',
    fileName: 'Smetana_Moldau'
  }]
},

{
  id: 18,
  name: 'Tchaikovsky',
  songs: [{
    tuneId: 1,
    Composer: 'Tchaikovsky',
    songTitle: 'Swan Lake',
    keyOriginal: 'B-',
    fileName: 'Tchaikovsky_Swan Lake'
  }]
}, 

{
  id: 19,
  name: 'Verdi',
  songs: [{
    tuneId: 1,
    Composer: 'Verdi',
    songTitle: 'La Donna e Mobile',
    keyOriginal: 'B',
    fileName: 'Verdi_La Donna e Mobile'
  }]
}, 

{
  id: 20,
  name: 'Vivaldi',
  songs: [{
    tuneId: 1,
    Composer: 'Vivaldi',
    songTitle: 'Four Seasons - Winter 2nd mvmt',
    keyOriginal: 'Eb',
    fileName: 'Vivaldi_The Four Seasons-WInter 2nd Movement'
  }]
},

{
  id: 21,
  name: 'label',
  songs: [{
    tuneId: 1,
    Composer: 'temp t',
    songTitle: 'temp',
    keyOriginal: 'G',
    fileName: 'temp'
  }, {
    tuneId: 2,
    Composer: 'temp t',
    songTitle: 'temp',
    keyOriginal: 'Eb',
    fileName: 'temp'
  }]
},




]


export default peopleList