import React from 'react';
// import { decorate, observable, configure, action } from "mobx";
import { observer } from "mobx-react";
// import { Component } from 'react';
// import { peopleList } from './data';
import { appStore } from './App';
// import Store from './App';
// import { LeftSideBar } from './LeftSideBar';

// configure({ enforceActions: "observed" });

export class BrandLogo extends React.Component {
  render() {
    const { logoClicked } = this.props.store;
    return(
      
      <input type="image" alt="Classical Chord Book" className="brandLogo" onClick={logoClicked} src="assets/xx_images/Logo_horizontal_slim_trimmed.png"></input>
      
      );
  }
}
BrandLogo = observer(BrandLogo)



export class IconHowTo extends React.Component {
  render() {
    const { howToClicked } = this.props.store;
    return(
      
      <button type="text" className="menuIcon howToIcon" onClick={howToClicked} >Scores</button>
      
      );
  }
}
IconHowTo = observer(IconHowTo)


export class IconContact extends React.Component {
  render() {
    const { contactClicked } = this.props.store;
    return(
      
      <button type="text" className="menuIcon contactIcon" onClick={contactClicked} >Contact</button>
      
      );
  }
}
IconContact = observer(IconContact)






export class KeySelector extends React.Component {

  render() {
    const { selectYourKey } = this.props.store;
    // console.log(keySelected);

    return(
      <div className="btn-group dropright keySelector">
      <button type="button" className="btn-sm btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      Key selector
      </button>
      <div className="dropdown-menu">
          <button className="dropdown-item link-button" onClick={selectYourKey} value="+6">+6</button>
          <button className="dropdown-item link-button" onClick={selectYourKey} value="+5">+5</button>
          <button className="dropdown-item link-button" onClick={selectYourKey} value="+4">+4</button>
          <button className="dropdown-item link-button" onClick={selectYourKey} value="+3">+3</button>
          <button className="dropdown-item link-button" onClick={selectYourKey} value="+2">+2</button>
          <button className="dropdown-item link-button" onClick={selectYourKey} value="+1">+1</button>
          <button className="dropdown-item link-button" onClick={selectYourKey} value="Default key">Original</button>
          <button className="dropdown-item link-button" onClick={selectYourKey} value="-1">-1</button>
          <button className="dropdown-item link-button" onClick={selectYourKey} value="-2">-2</button>
          <button className="dropdown-item link-button" onClick={selectYourKey} value="-3">-3</button>
          <button className="dropdown-item link-button" onClick={selectYourKey} value="-4">-4</button>
          <button className="dropdown-item link-button" onClick={selectYourKey} value="-5">-5</button>
          <button className="dropdown-item link-button" onClick={selectYourKey} value="-6">-6</button>
      </div>
      </div>

      );
  }
}
KeySelector = observer(KeySelector)


export class Clear extends React.Component {
  render() {
    return(
      <div className="clear"> </div>
      )
  }
}


export class CurrentKey extends React.Component {

render() {
  const { keySelected } = this.props.store;
  return(
    <div className="currentKey">
    <p>Selected key = {keySelected}</p>

    </div>
    )
}

}
CurrentKey = observer(CurrentKey)


export class TopBar extends React.Component {

  render() {
    // const { keySelected, selectYourKey } = this.props.store;
    // console.log("from TopBar: " + keySelected);

    return(
      <div className="topBarSection">
      <BrandLogo store={appStore} />
      <IconHowTo store={appStore} />
      <IconContact store={appStore} />
      <KeySelector store={appStore} />

      </div>
      );
    }
  }
TopBar = observer(TopBar)

export class TopBar2 extends React.Component {

  render() {
    // const { keySelected, selectYourKey } = this.props.store;
    // console.log("from TopBar: " + keySelected);

    return(
      <div className="topBarSection">
      <BrandLogo store={appStore} />
      <IconHowTo store={appStore} />
      <IconContact store={appStore} />
      </div>
      );
    }
  }
TopBar2 = observer(TopBar2)




// export { TopBar };
// export { KeySelector };